import { render, staticRenderFns } from "./Nickname.vue?vue&type=template&id=fb688a06&scoped=true&lang=pug&"
import script from "./Nickname.vue?vue&type=script&lang=ts&"
export * from "./Nickname.vue?vue&type=script&lang=ts&"
import style0 from "./Nickname.vue?vue&type=style&index=0&id=fb688a06&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb688a06",
  null
  
)

export default component.exports